const state = {
  currentOrder: null,
};

const mutations = {
  updateOrder(nextState, order){
    nextState.currentOrder = order;
  },
};

const actions =  {
  setCurrentOrder: (context, order) => context.commit('updateOrder', order),
  removeCurrentOrder: (context) => context.commit('updateOrder', null),
};

export default {
  state,
  actions,
  mutations,
};
