const state = {
  currentCustomer: null,
};

const mutations = {
  updateCustomer(nextState, customer){
    nextState.currentCustomer = customer;
  },
};

const actions =  {
  setCurrentCustomer: (context, customer) => context.commit('updateCustomer', customer),
  removeCurrentCustomer: (context) => context.commit('updateCustomer', null),
};

export default {
  state,
  actions,
  mutations,
};
