const state = {
  currentPromotion: null,
};

const mutations = {
  updatePromotion(nextState, promotion){
    nextState.currentPromotion = promotion;
  },
};

const actions =  {
  setCurrentPromotion: (context, promotion) => context.commit('updatePromotion', promotion),
  removeCurrentPromotion: (context) => context.commit('updatePromotion', null),
};

export default {
  state,
  actions,
  mutations,
};
