const state = {
  currentUser: null,
};

const mutations = {
  updateUser(nextState, user){
    nextState.currentUser = user;
  },
};

const actions =  {
  setCurrentUser: (context, user) => context.commit('updateUser', user),
  removeCurrentUser: (context) => context.commit('updateUser', null),
};

export default {
  state,
  actions,
  mutations,
};
