const state = {
    currentDocument: null,
  };
  
  const mutations = {
    updateDocument(nextState, document){
      nextState.currentDocument = document;
    },
  };
  
  const actions =  {
    setCurrentDocument: (context, document) => context.commit('updateDocument', document),
    removeCurrentDocument: (context) => context.commit('updateDocument', null),
  };
  
  export default {
    state,
    actions,
    mutations,
  };